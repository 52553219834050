<template>
  <div class="account public-box">
    <!-- <div class="title"></div> -->
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="收款账户名" prop="region">
        <el-col :span="6">
          <el-select style="width: 100%;" v-model="ruleForm.id" @change="changeMkt" placeholder="请选择">
            <el-option v-for="item in dataList" :key="item.id" :label="item.mkt_mrch_Nm" :value="item.id"></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <!-- <el-form-item label="收款账户证件号" prop="region">
        <el-col :span="6">
          <el-input disabled v-model="ruleForm.mkt_mrch_Id" placeholder="根据账户名自动调出"></el-input>
        </el-col>
      </el-form-item> -->
      <el-form-item label="收款账户号" prop="region">
        <el-col :span="6">
          <el-input disabled v-model="ruleForm.mkt_mrch_Id" placeholder="根据账户名自动调出"></el-input>
        </el-col>
      </el-form-item>
      <div class="tips">注：没有绑定收款账号之前所有线上收款将进入平台账号中，在没有绑定收款账号之前请谨慎使用线上收款</div>
      <MyButton class="btn" :accessId="13569" title="保存" @click="save"></MyButton>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "account",
  data() {
    return {
      dialogVisible: false,
      rules: {},
      ruleForm: {},
      dataList: []
    }
  },
  created() {
    //do something after creating vue instance
    this.getList();
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$request.HttpPost('/market/marketInfo').then(res => {
        if (res.data.enterprise_info_value) {
          this.ruleForm = res.data.enterprise_info_value;
        }
      });
    },
    changeMkt(id) {
      for (let i in this.dataList) {
        if (this.dataList[i].id == id) {
          this.ruleForm = this.dataList[i];
        }
      }
    },
    getList() {
      this.$request.HttpGet('/market/marketEnterprise').then(res => {
        if (res.data) {
          res.data = JSON.parse(res.data);
          this.dataList = res.data;
        }
      });
    },
    save() {
      this.$request.HttpGet('/market/marketEdit', {
        enterprise_info: JSON.stringify(this.ruleForm)
      }).then(res => {
        this.$common.notifySuccess('设置成功');
      })
    }
  }
}
</script>

<style scoped lang="scss">
.account {
  .title{
    flex: 1;
    font-size: 16px;
    border-bottom: 1px solid #DEDEDE;
    margin-bottom: 50px;
    padding: 10px 35px;
    box-sizing: border-box;
  }
  .demo-ruleForm{
    margin-left: 20px;
  }
  .tips {
    opacity: 0.5;
  }

  .btn {
    margin-top: 50px;
    position: relative;
    margin-left: 120px;
  }
}
</style>
